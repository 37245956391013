import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';

import withLocation from '../components/withLocation';
import Layout from '../components/layout';
import BlogList from '../components/blog-list';
import BlogCard from '../components/blog-card';
import PageTitle from '../components/page-title';

const SearchTag = styled.span`
  @import url('https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap');
  font-family: 'Source Code Pro';
  text-decoration: underline;
  color: rebeccapurple;
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 5px;
`;

const Blog = ({ search }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            excerpt
            frontmatter {
              title
              publishedDate
              updatedDate
              tags
              cardImage
              imageAlt
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const filteredPosts = search.tag
    ? data.allMarkdownRemark.edges.filter(post =>
        post.node.frontmatter.tags.includes(search.tag)
      )
    : data.allMarkdownRemark.edges;

  const sortByFrontmatterKey = key => {
    return function(a, b) {
      if (a.node.frontmatter[key] < b.node.frontmatter[key]) {
        return 1;
      }
      if (a.node.frontmatter[key] > b.node.frontmatter[key]) {
        return -1;
      }
      return 0;
    };
  };

  const sortedPosts = filteredPosts.sort(sortByFrontmatterKey('publishedDate'));

  return (
    <Layout>
      <PageTitle>
        {search.tag ? (
          <>
            {'Posts about '}
            <SearchTag>{search.tag}</SearchTag>
          </>
        ) : (
          'All Posts'
        )}
      </PageTitle>
      {search.tag && <Link to="/blog">Back to All posts</Link>}
      <BlogList>
        {sortedPosts.map(blog => {
          const {
            title,
            publishedDate,
            updatedDate,
            tags,
            cardImage,
            imageAlt,
          } = blog.node.frontmatter;
          const { slug } = blog.node.fields;
          return (
            <li key={title}>
              <BlogCard
                slug={slug}
                title={title}
                publishedDate={publishedDate}
                updatedDate={updatedDate}
                tags={tags}
                excerpt={blog.node.excerpt}
                image={cardImage}
                imageAlt={imageAlt}
              />
            </li>
          );
        })}
      </BlogList>
    </Layout>
  );
};

export default withLocation(Blog);
